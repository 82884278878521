import React from 'react'
import { createContainer } from 'unstated-next'
import { popupNavigator } from '../../popupNavigator'
import { api } from '../../api'
import { Notification, NotificationView } from './models'
import { createViewMapper } from './mapper'
import { useActionDebounce } from '../../../app/common/debounce'
import { UserContextContainer } from '../../signIn/userContext'

export function useNotifications() {
    let [list, setList] = React.useState<NotificationView[]>([])
    let [isOpen, setIsOpen] = React.useState<boolean>(false)
    let userContext = UserContextContainer.useContainer()

    React.useEffect(() => reloadEvery(fiveMinutes), [])
    React.useEffect(() => { load() }, [userContext.isLoggedIn])

    let loadDebounced = useActionDebounce(load, fifteenSeconds)
    let { mapToView } = createViewMapper(popupNavigator)

    async function load() {
        if (!userContext.isLoggedIn) return
        let result = await api.get<Notification[]>('stock/movement/notification')
        let views = result.map(mapToView)
        setList(views)
    }

    function reloadEvery(delayInMs: number) {
        loadDebounced.execute()
        setTimeout(() => reloadEvery(delayInMs), delayInMs)
    }

    return { list, isOpen, setIsOpen, load: loadDebounced.execute }
}

export let NotificationContainer = createContainer(useNotifications)
export type NotificationStore = ReturnType<typeof useNotifications>

const fiveMinutes = 5 * 60 * 1000
const fifteenSeconds = 15 * 1000