import { hasFeature } from "../../infrastructure/feature";
import { AdminCategory } from "./adminModels";
import { Claims } from "../../infrastructure/signIn/models";

export function Categories(): AdminCategory[] {
    return [
        {
            name: 'administration',
            cards: [
                {
                    name: 'featureToggle',
                    claims: [Claims.FeatureToggle],
                    hasDescription: true
                },
                {
                    name: 'refreshData',
                    claims: [Claims.RefreshData],
                    hasDescription: true
                },
                {
                    name: 'clearData',
                    claims: [Claims.ClearData],
                    hasDescription: true
                },
                {
                    name: 'impersonate',
                    claims: [Claims.Impersonate],
                    hasDescription: true
                },
                {
                    name: 'profile',
                    claims: [Claims.UserPersonaManager],
                    hasDescription: true
                },
                {
                    name: 'persona',
                    claims: [Claims.PersonaManager],
                    hasDescription: true
                }
            ]
        },
        {
            name: 'company',
            cards: [
                {
                    name: 'company',
                    claims: [Claims.MasterdataCompanyManager, Claims.MasterdataCompanyReader]
                },
                {
                    name: 'counterparty',
                    claims: [Claims.MasterdataCounterpartyManager, Claims.MasterdataCounterpartyReader]
                },
                {
                    name: 'companyDutyStatus',
                    claims: [Claims.MasterdataCompanyDutyStatusManager, Claims.MasterdataCompanyDutyStatusReader]
                }
            ]
        },
        {
            name: 'deals',
            cards: [
                {
                    name: 'dealType',
                    claims: [Claims.MasterdataDealTypeManager, Claims.MasterdataDealTypeReader]
                },
                {
                    name: 'pricingPeriod',
                    claims: [Claims.MasterdataPricingPeriodManager]
                },
                {
                    name: 'regulatedPrice',
                    claims: [Claims.MasterdataRegulatedPriceManager, Claims.MasterdataRegulatedPriceReader]
                },
                {
                    name: 'quantityUnitPrice',
                    claims: [Claims.MasterdataQuantityUnitPriceManager, Claims.MasterdataQuantityUnitPriceReader]
                },
                {
                    name: 'paymentTerm',
                    claims: [Claims.MasterdataPaymentTermReader],
                    url: 'deal/paymentTerm'
                },
                {
                    name: 'quotation',
                    claims: [Claims.MasterdataQuotationReader],
                    url: 'pricing/priceIndex'
                },
                {
                    name: 'quotationStub',
                    claims: [Claims.MasterdataQuotationStubReader],
                    url: 'deal/quotationStub'
                },
                {
                    name: 'dealValidatorEmail',
                    claims: [Claims.MasterdataDealValidatorEmailReader],
                    url: 'deal/dealValidatorEmail'
                },
                {
                    name: 'validationProcess',
                    claims: [Claims.MasterdataValidationProcessReader],
                    url: 'deal/validationProcess'
                },
                {
                    name: 'incoterm',
                    claims: [Claims.MasterdataIncoTermReader],
                    url: 'deal/incoterm'
                }
            ]
        },
        {
            name: 'product',
            cards: [
                {
                    name: 'productCompany',
                    claims: [Claims.MasterdataProductCompanyManager, Claims.MasterdataProductCompanyWriter]
                },
                {
                    name: 'productgroup',
                    claims: [Claims.MasterdataProductGroupSecurityStockLevel]
                },
                {
                    name: 'localproduct',
                    claims: [Claims.MasterdataLocalProduct]
                },
                {
                    name: 'product',
                    claims: [Claims.MasterdataProductReader, Claims.MasterdataProductWriter, Claims.MasterdataProductManager]
                }
            ]
        },
        {
            name: 'sap',
            cards: [
                {
                    name: 'sapCounterparty',
                    claims: [Claims.MasterdataSapCounterpartyManager, Claims.MasterdataSapCounterpartyReader]
                },
                {
                    name: 'sapCounterpartyDealType',
                    claims: [Claims.MasterdataSapCounterpartyManager, Claims.MasterdataSapCounterpartyReader]
                },
                {
                    name: 'storageLocation',
                    claims: [Claims.MasterdataPlantStorageLocationManager, Claims.MasterdataPlantStorageLocationWriter]
                },
                {
                    name: 'plant',
                    claims: [Claims.MasterdataPlantManager]
                },
                {
                    name: 'sapShipTo',
                    claims: [Claims.MasterdataSapShipToReader, Claims.MasterdataSapShipToManager]
                },
                {
                    name: 'sapPurchaseOrganization',
                    claims: [Claims.MasterdataSapPurchaseOrganizationReader],
                    url: 'masterdata/sapCounterparty/allSapPurchaseOrganization'
                },
                {
                    name: 'productSAPCode',
                    claims: [Claims.MasterdataProductSAPCodeReader],
                    url: 'deal/allProductSapCodes'
                },
                {
                    name: 'sapCustomerSegment',
                    claims: [Claims.MasterdataSapCustomerSegmentReader],
                    url: 'sapApi/sapCustomerSegments'
                },
                {
                    name: 'sapDocumentType',
                    claims: [Claims.MasterdataSapDocumentTypeReader],
                    url: 'sapApi/sapDocumentTypes'
                },
                {
                    name: 'sapDealFieldPricingConditions',
                    claims: [Claims.MasterdataSapDealFieldPricingConditionsReader],
                    url: 'masterdata/dealFieldPricingConditions'
                },
                {
                    name: 'sapPricingConditionFieldMappings',
                    claims: [Claims.MasterdataSapDealFieldPricingConditionsReader],
                    url: 'masterdata/pricingConditionFieldMappings'
                }
            ]
        },
        {
            name: 'stock',
            cards: [
                {
                    name: 'stockCapacity',
                    claims: [Claims.MasterdataStockCapacityManager, Claims.MasterdataStockCapacityReader],
                    hasDescription: true
                },
                {
                    name: 'securityStock',
                    claims: [Claims.MasterdataSecurityStockLevel],
                    hasDescription: true
                },
                {
                    name: 'physicalStock',
                    claims: [Claims.MasterdataPhysicalStockManager, Claims.MasterdataPhysicalStockReader],
                    hasDescription: true
                },
                {
                    name: 'tactixOutput',
                    claims: [Claims.TactixOutputReader],
                    url: 'stock/movement/mktsale/tactixOutput'
                }
            ]
        },
        {
            name: 'site',
            cards: [
                {
                    name: 'supplyOrigin',
                    claims: [Claims.MasterdataSupplyOriginManager, Claims.MasterdataSupplyOriginReader]
                },
                {
                    name: 'supplyDestination',
                    claims: [Claims.MasterdataSupplyDestinationManager, Claims.MasterdataSupplyDestinationWriter, Claims.MasterdataSupplyDestinationReader]
                },
                {
                    name: 'site',
                    claims: [Claims.MasterdataSiteManager, Claims.MasterdataSiteReader]
                },
                {
                    name: 'supplyPlantAssignment',
                    claims: [Claims.MasterdataSupplyPlantAssignmentReader, Claims.MasterdataSupplyPlantAssignmentManager]
                }
            ]
        },
        {
            name: 'vessel',
            cards: [
                {
                    name: 'vesselDelay',
                    claims: [Claims.MasterDataVesselDelayManager, Claims.MasterDataVesselDelayReader]
                },
                {
                    name: 'jetty',
                    claims: [Claims.MasterdataJettyManager, Claims.MasterdataJettyReader, Claims.MasterdataJettyWriter]
                }
            ]
        },
        {
            name: 'utils',
            cards: [
                {
                    name: 'calendar',
                    claims: [Claims.MasterdataCalendarManager, Claims.MasterdataCalendarReader]
                },
                {
                    name: 'conversionFactor',
                    claims: [Claims.MasterdataCommonReader],
                    url: 'quantity/conversionFactor'
                },
                {
                    name: 'meanOfTransportation',
                    claims: [Claims.MasterdataMeanOfTransportationReader],
                    url: 'stock/movement/masterdataMeanOfTransportation'
                },
                {
                    name: 'documentPath',
                    claims: [Claims.MasterdataDocumentPathManager, Claims.MasterdataDocumentPathReader],
                    url: 'document/documentPath'
                }
            ]
        },
        {
            name: 'customer',
            cards: [
                {
                    name: 'customerSegment',
                    claims: [Claims.MasterdataCustomerSegmentReader],
                    url: 'stock/channelCustomerSegment'
                },
                {
                    name: 'customerSegmentProduct',
                    claims: [Claims.MasterdataCustomerSegmentProductReader],
                    url: 'stock/channelCustomerSegmentProduct'
                }
            ]
        },
        {
            name: 'pipeline',
            cards: hasFeature('MessageMovements') ? [
                {
                    name: 'transcosCounterParty',
                    claims: [Claims.MasterdataTranscosCounterPartyReader],
                    url: 'stock/movementMessage/transcos?type=CounterParty'
                },
                {
                    name: 'transcosProduct',
                    claims: [Claims.MasterdataTranscosProductReader],
                    url: 'stock/movementMessage/transcos?type=Product'
                },
                {
                    name: 'transcosSite',
                    claims: [Claims.MasterdataTranscosSiteReader],
                    url: 'stock/movementMessage/transcos?type=Site'
                },
                {
                    name: 'messagesRules',
                    claims: [Claims.MasterdataMovementMessagesRulesReader],
                    url: 'stock/movementMessage/messagesRules'
                }
            ] : []
        },
        {
            name: 'primo',
            cards: [
                {
                    name: 'primoMovementType',
                    claims: [Claims.MasterdataPrimoMovementTypeReader],
                    url: 'report/primo/primoMovementType'
                },
                {
                    name: 'primoPricingArea',
                    claims: [Claims.MasterdataPrimoPricingAreaReader],
                    url: 'report/primo/primoPricingArea'
                },
                {
                    name: 'primoPricingFormula',
                    claims: [Claims.MasterdataPrimoPricingFormulaReader],
                    url: 'report/primo/primoPricingFormula'
                },
                {
                    name: 'primoTranscoMot',
                    claims: [Claims.MasterdataPrimoTranscoMotReader],
                    url: 'report/primo/primoTranscoMot'
                },
                {
                    name: 'primoTranscoProduct',
                    claims: [Claims.MasterdataPrimoTranscoProductReader],
                    url: 'report/primo/primoTranscoProduct'
                },
                {
                    name: 'primoPriceIndexCode',
                    claims: [Claims.MasterdataPrimoPriceIndexCodeReader],
                    url: 'report/primo/primoPriceIndexCode'
                }
            ]
        },
        {
            name: 'fifo',
            cards: [
                {
                    name: 'fifoPerimeters',
                    claims: [Claims.FifoDashboardExport],
                    url: 'report/fifo/perimeter'
                },
                {
                    name: 'fifoTransferOnlySite',
                    claims: [Claims.FifoDashboardExport],
                    url: 'report/fifo/transferOnlySite'
                },
            ]
        }
    ]
}